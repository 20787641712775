import {
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import React from 'react';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.primary.contrastText,
    },
  }),
);

export function LoadingBackdrop() {
  const classes = useStyles();
  return (
    <Backdrop className={classes.root} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
